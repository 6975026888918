import React, { Component } from 'react'
import axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL} from '../../../config/config'
// IMAGE_THUMB_URL
import NumberFormat from 'react-number-format';
import * as moment from 'moment';
import 'moment/locale/th';
import {connect} from "react-redux";
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";

const port = Number(window.env.PORT);

class CarRepairMainList extends Component {

  constructor (props) {
    super(props)

    this.state = {
      lists: [],
      load_data: true,
      toggle_car_status_edit: false,
      car_id: '',
        user: {},
        menuID: [3],
        grouppermissions: [],
    }

    this.toggleCarStatusEdit = this.toggleCarStatusEdit.bind(this)
    this.onCarStatusChange = this.onCarStatusChange.bind(this)
      this.loadGroupPermission = this.loadGroupPermission.bind(this);
  }

    loadGroupPermission() {
        let type = this.state.user.type
        let menuID = this.state.menuID
        getGroupPermission(type, menuID).then(res => {
            if(res.data.result === 'success') {
                this.setState({
                    grouppermissions: res.data.grouppermissions
                })
            }
        })
    }


    loadData () {
    // alert(APIURL + '/car/car_repair/mainlist/')
    let username = this.state.user.user;
    axios.get(APIURL + '/car/car_repair/mainlist/'+username)
      .then(res => {
        this.setState({lists: res.data, load_data: false, car_id: 0, danger: false});
      }).catch(error => {
        this.setState({lists: [], load_data: true, car_id: 0, danger: false});
    });
  }

    componentWillMount () {

        this.setState({
            user: JSON.parse(this.props.user),
           /* car_id: car.car_id,
            upload_code: car.upload_code*/
        });
    }

  componentDidMount () {
      CarsUtil.removeCarSelect()
      CarSellUtil.removeCarSellSelect()
    //if(this.state.load_data)
    this.loadGroupPermission()
    this.loadData()
  }

  toggleCarStatusEdit(){
    this.setState({toggle_car_status_edit: !this.state.toggle_car_status_edit})
  }

  onCarStatusChange(){
    //alert("car_id="+car_id+" , car_status_id="+car_status_id)
    let car_status_id = 4
    let car_id = this.state.car_id
    axios.put(APIURL + '/car/car_status_repair_complete/'+car_id,{
      car_id: car_id,
      car_status_id: car_status_id,
    })
      .then(res => {
        this.loadData()
        this.toggleCarStatusEdit()
      }).catch(error => {

    })
  }


  render () {

    let tbodyList = this.state.lists.map((car, index) => {

      let image_url = ''
      if (car.car_upload_filename.includes('https')) {
        image_url = car.car_upload_filename
      } else {
          if(car.car_upload_filename === null || car.car_upload_filename === '') {
              image_url= IMAGE_NOPHOTO_URL
          } else {
              image_url = IMAGE_FULL_URL + car.car_upload_filename
          }
      }

      // console.log(car)
      let order = index + 1
      const car_license_plate = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.car_license_plate_new : car.car_license_plate_old

      return (

        <tr key={index}>
          <td>{order}</td>
          <td>
            <img
              src={image_url}
              style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
              alt="ภาพเอกสาร"
            />
            <br/>
            <strong> {car.car_name}
            <br/>
            ทะเบียนรถ: {car_license_plate}
            </strong>
          </td>
          <td>
            ส่งซ่อม &nbsp;&nbsp;&nbsp;: {moment(car.car_repair_send).locale('th').format('LL')}
            <br/><br/>
            แล้วเสร็จ : {moment(car.car_repair_complete).locale('th').format('LL')}
            <br/><br/>
            อู่ : {car.center_name}
          </td>
          <td className={port === 9040 && this.state.user.type !== 'admin'? 'hidden' : "text-left"}>
            <NumberFormat value={car.car_repair_cost} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
          </td>
          <td>
          <span
            className='badge'
            style={{backgroundColor: car.car_status_color}}
          >
             {car.car_status_name}
          </span>
            <br/>
            รอรับรถ {car.car_repair_count} รายการ
          </td>
          <td className="align-top">

              {
                  checkGroupPermission(3, this.state.grouppermissions).created ? (
                      <button type="button"
                              className={port === 9040 && this.state.user.type !== 'admin'? 'hidden' : "btn btn-danger btn-sm btn-block"}
                              onClick={()=>{
                                  var target = "/car-repair/new?car_id="+car.car_id+"&toggle=new" ;
                                  this.props.history.push(target);
                              }}
                      >
                          <i className="icon-wrench"/> เพิ่มรายการซ่อม
                      </button>
                  ) : null
              }

            {
                checkGroupPermission(3, this.state.grouppermissions).modified ? (
                    <button type="button"
                            className="btn btn-success btn-sm btn-block"
                            onClick={()=>{
                                this.setState({
                                    car_id: car.car_id
                                },()=>{
                                    this.toggleCarStatusEdit()
                                })
                            }}
                    >
                        <i className="icon-check"/> ซ่อมเสร็จ
                    </button>
                ) : null
            }

            {
                checkGroupPermission(3, this.state.grouppermissions).viewed ? (
                    <button type="button"
                            className="btn btn-info btn-sm btn-block"
                            onClick={()=>{
                                var target = "/car-repair/new?car_id="+car.car_id ;
                                this.props.history.push(target);
                            }}
                    >
                        <i className="icon-list"/> รายการซ่อม
                    </button>
                ) : null
            }

          </td>
        </tr>
      )
    })

    let tbodyEmpty = ()=>{
      return (
        <tr className="text-center">
          <td colSpan={6}><span className="text-danger">ไม่พบข้อมูล</span></td>
        </tr>
      )};

    return (

      <div className="card">
        <div className="card-block">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-warning">
                <div className="card-header card-sparepart">
                  <strong className="text-title"> ข้อมูลการซ่อมบำรุงรถ </strong>
                  { /*<button className="btn btn-primary float-right">
                   <Link  to="/car-repair/new" style={{color: 'white'}} activeClassName="active" > <i className="icon-plus"></i> เพิ่มข้อมูลการซ่อมบำรุง
                   </Link>
                   </button>*/}
                </div>
                <div className="card-block">
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center" width="20%">ข้อมูลรถ</th>
                      <th>วันที่ส่งซ่อม/แล้วเสร็จ</th>
                      <th className={port === 9040 && this.state.user.type !== 'admin'? 'hidden' : "text-left"}>ค่าซ่อมรวม</th>
                      <th>สถานะ</th>
                      <th className="text-center">จัดการ</th>
                    </tr>
                    </thead>
                    <tbody>
                      {(tbodyList.length>0)? tbodyList: tbodyEmpty()}
                    </tbody>
                  </table>
                  {/*<ul className="pagination">
                    <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                    <li className="page-item active">
                      <a className="page-link" href="#">1</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">4</a></li>
                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                  </ul>*/}
                </div>

                <Modal isOpen={this.state.toggle_car_status_edit} toggle={this.toggleCarStatusEdit} className="'modal-lg modal-warning">
                  <ModalHeader toggle={this.toggleCarStatusEdit.bind(this)}>แก้ไขสถานะรถ</ModalHeader>
                  <ModalBody>
                    <div className="h5 text-gray-dark"> คุณต้องการแก้ไขสถานะรถ เป็น ซ่อมเสร็จ ใช่หรือไม่ ?? </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary"
                            onClick={this.toggleCarStatusEdit.bind(this)}>ยกเลิก</Button>
                    <Button color="primary"
                            onClick={this.onCarStatusChange.bind(this, this.state.car_doc_delete)}>ตกลง</Button>{' '}
                  </ModalFooter>
                </Modal>

              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    type: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarRepairMainList);
